export const SECONDS_IN_MINUTE = 60;
export const MINUTES_IN_HOUR = 60;

export enum DATE_UNIT {
  day = "day",
  week = "week",
  month = "month",
  year = "year",
  ms = "ms"
}

export const DATE_FORMAT = "MM/DD/YY";
export const DATE_FORMAT_FULL_YEAR = "MM/DD/YYYY";
export const DATE_VIEW_FORMAT = "MM/DD/YY | hh:mm A";
export const DATE_VIEW_FORMAT_MOBILE = "MM/DD/YY hh:mm A";
export const DATE_DASH_VIEW_FORMAT = "MM-DD-YY | hh:mm A";
export const MONTH_FORMAT = "MMM YYYY";
export const MONTH_FULL_FORMAT = "MMMM YYYY";
export const DAY_FORMAT = "D";
export const MONTH_FULL = "MMMM";
export const YEAR_FULL = "YYYY";

export enum PERIOD {
  lastWeek = "lastWeek",
  thisWeek = "thisWeek",
  thisMonth = "thisMonth",
  lastMonth = "lastMonth",
  custom = "custom"
}

export enum CUSTOM_PERIOD {
  last30Days = "last30Days",
  last60Days = "last60Days",
  last90Days = "last90Days",
  thisYear = "thisYear",
  last365Days = "last365Days",
  allTime = "allTime"
}

export const PERIODS = [
  {
    period: PERIOD.thisMonth,
    title: "This month"
  },
  { period: PERIOD.thisWeek, title: "This week" },
  {
    period: PERIOD.lastMonth,
    title: "Last month"
  },
  { period: PERIOD.lastWeek, title: "Last week" },
  { period: PERIOD.custom, title: "Custom" }
];

export const STATIC_VARIANTS: Array<{
  label: string;
  active: boolean;
  period: PERIOD | CUSTOM_PERIOD;
}> = [
  { label: "Custom", active: false, period: PERIOD.custom },
  { label: "Last 30 days", active: true, period: CUSTOM_PERIOD.last30Days },
  { label: "Last 60 days", active: true, period: CUSTOM_PERIOD.last60Days },
  { label: "Last 90 days", active: true, period: CUSTOM_PERIOD.last90Days },
  { label: "This Year", active: true, period: CUSTOM_PERIOD.thisYear },
  { label: "Last 365 days", active: true, period: CUSTOM_PERIOD.last365Days },
  { label: "All Time", active: true, period: CUSTOM_PERIOD.allTime }
];

export const DYNAMIC_VARIANTS: Array<{
  label: string;
  offset: number;
  type: DATE_UNIT;
}> = [
  { label: "days up to today", offset: 0, type: DATE_UNIT.day },
  { label: "days up to yesterday", offset: 1, type: DATE_UNIT.day }
];
