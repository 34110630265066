import Tooltip from "@material-ui/core/Tooltip";
import withStyles from "@material-ui/styles/withStyles/withStyles";

import Theme from "~/declarations/theme";

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.grey[900],
    boxShadow: `0 3px 5px ${theme.palette.grey[800]}`,
    fontSize: 11,
    justifyContent: `end`
  },
  arrow: {
    color: theme.palette.common.white
  }
}))(Tooltip);

export default LightTooltip;
