import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import * as React from "react";

import styled from "~/components/core/styled";
import { Align } from "~/components/core/Table/Table";
import { SORT } from "~/constants/filters";

export interface SortableProps<T> {
  align?: Align;
  id?: keyof T;
  sortType?: SORT;
  sortColumn?: keyof T;
  onClickSort?: (id: keyof T | undefined, sort: SORT) => void;
}

const ACTIVE_ASC_SORT_POSITIVE = "asc";
const ACTIVE_DESC_SORT_POSITIVE = "desc";

export default function TableHeaderCellSort<T>({
  id,
  sortType,
  sortColumn,
  onClickSort,
  align
}: SortableProps<T>) {
  const [controls, setControls] = React.useState(false);
  const active = id === sortColumn;
  const activeAscSort = sortType === SORT.asc && active;
  const activeDescSort = sortType === SORT.desc && active;
  const handleMouseOver = (): void => setControls(true);

  const handleMouseLeave = (): void => setControls(false);

  const handleSortDesc = (): void => {
    if (activeDescSort) {
      onClickSort?.(undefined, SORT.noSort);
    } else {
      id && onClickSort?.(id, SORT.desc);
    }
  };

  const handleSortAsc = (): void => {
    if (activeAscSort) {
      onClickSort?.(undefined, SORT.noSort);
    } else {
      id && onClickSort?.(id, SORT.asc);
    }
  };

  const handleNoSort = (): void => {
    if (!active) {
      id && onClickSort?.(id, SORT.asc);
    } else if (sortType === SORT.asc) {
      id && onClickSort?.(id, SORT.desc);
    } else if (sortType === SORT.desc) {
      id && onClickSort?.(undefined, SORT.noSort);
    } else if (sortType === SORT.noSort) {
      id && onClickSort?.(id, SORT.asc);
    }
  };

  return (
    <Wrapper
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      align={align}
    >
      <Sorting visible={Boolean(controls || active)}>
        <StyledExpandLess
          active={activeAscSort ? ACTIVE_ASC_SORT_POSITIVE : ""}
          onClick={handleSortAsc}
        />
        <StyledExpandMore
          active={activeDescSort ? ACTIVE_DESC_SORT_POSITIVE : ""}
          onClick={handleSortDesc}
        />
      </Sorting>
      <SortingName onClick={handleNoSort} />
    </Wrapper>
  );
}

interface SortingProps {
  visible: boolean;
}

interface IconProps {
  active: string;
}

const StyledExpandLess = styled(ExpandLess)<IconProps>`
  color: ${({ active, theme }): string =>
    active === ACTIVE_ASC_SORT_POSITIVE
      ? theme.palette.common.black
      : "default"};
`;

const StyledExpandMore = styled(ExpandMore)<IconProps>`
  color: ${({ active, theme }): string =>
    active === ACTIVE_DESC_SORT_POSITIVE
      ? theme.palette.common.black
      : "default"};
`;

const Sorting = styled.div<SortingProps>`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
  visibility: ${({ visible }): string => (visible ? "visible" : "hidden")};

  svg {
    cursor: pointer;
    height: 15px;
    width: 15px;

    &:hover {
      color: ${({ theme }): string => theme.palette.common.black};
    }
  }
`;

const Wrapper = styled.div<{ align?: Align }>`
  align-items: center;
  display: flex;
  height: 30px;
  justify-content: ${({ align }): string => align ?? ""};
`;

const SortingName = styled.span`
  cursor: pointer;

  &:hover {
    color: ${({ theme }): string => theme.palette.common.black};
  }
`;
