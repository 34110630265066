import TableCell, { TableCellProps } from "@material-ui/core/TableCell";
import * as React from "react";

import ClarificationIcon from "~/components/core/ClarificationIcon";
import styled from "~/components/core/styled";

import TableHeaderCellSort, { SortableProps } from "./TableHeaderCellSort";

interface Props<T> extends SortableProps<T> {
  sortBy?: keyof T;
  name: string;
  clarification?: string;
}

function TableHeaderCell<T>({
  align,
  name,
  clarification,
  sortBy,
  ...sortableProps
}: Props<T>): JSX.Element {
  return (
    <StyledHeadCell padding={sortBy ? "none" : "default"}>
      <ContentWrapper align={align}>
        {sortBy && <TableHeaderCellSort {...sortableProps} />}
        {name}
        {clarification && (
          <StyledClarificationIcon clarification={clarification} />
        )}
      </ContentWrapper>
    </StyledHeadCell>
  );
}

export default TableHeaderCell;

const StyledHeadCell = styled(TableCell)`
  border-top: none;
  line-height: 1rem;
  padding: 8px 3px;
`;

const ContentWrapper = styled.div<{ align: TableCellProps["align"] }>`
  align-items: center;
  display: flex;
  justify-content: ${({ align }): string => {
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  }};
`;

const StyledClarificationIcon = styled(ClarificationIcon)`
  height: 16px;
  width: 16px;
`;
