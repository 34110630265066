import { css, SerializedStyles } from "@emotion/core";
import { default as MuiTable } from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import * as React from "react";

import LoadingIndicator from "~/components/core/LoadingIndicator";
import styled from "~/components/core/styled";
import TableHeaderCell from "~/components/core/Table/TableHeaderCell";
import AccountLayoutContext from "~/components/layouts/AccountLayout/AccountLayoutContext";
import { SORT } from "~/constants/filters";
import { hexToRgba } from "~/utils/style";

export enum Align {
  inherit = "inherit",
  left = "left",
  center = "center",
  right = "right",
  justify = "justify"
}

const COMPACT_POSITIVE = "compact";

export interface TableColumn<T> {
  name: string;
  align?: Align;
  render?: (record: T) => JSX.Element;
  sortBy?: keyof T;
  clarification?: string;
}

export interface TableProps<T> {
  columns: TableColumn<T>[];
  loading?: boolean;
  children: React.ReactNode | React.ReactNode[];
  className?: string;
  striped?: boolean;
  verticalBorders?: boolean;
  stickyHeader?: boolean;
  footer?: React.ReactNode | React.ReactNode[];
  onSort?: (id: keyof T | undefined, sort: SORT) => void;
  sortColumn?: keyof T;
  sortType?: SORT;
  loadingCaption?: React.ReactNode;
  compact?: boolean;
  indicatorInViewport?: boolean;
  loadingWithData?: boolean;
}

const VERTICAL_BORDERS_POSITIVE = "borders";
const STRIPED_POSITIVE = "striped";
const DEFAULT_MESSAGE = "Sit tight, we’re working on it…";

function Table<T>({
  columns,
  children,
  loading,
  className,
  striped,
  verticalBorders,
  stickyHeader = false,
  footer,
  onSort,
  sortColumn,
  sortType,
  loadingCaption = DEFAULT_MESSAGE,
  compact,
  indicatorInViewport = false,
  loadingWithData = true
}: TableProps<T>): JSX.Element {
  const { mobileView } = React.useContext(AccountLayoutContext);

  return (
    <Wrapper className={className}>
      <MuiTable
        aria-label="simple table"
        stickyHeader={stickyHeader}
        size={mobileView ? "small" : "medium"}
      >
        {!compact && (
          <TableHead>
            <TableRow>
              {columns.map(
                ({
                  name,
                  align,
                  sortBy,
                  clarification
                }: TableColumn<T>): JSX.Element => (
                  <TableHeaderCell<T>
                    key={name}
                    id={sortBy}
                    onClickSort={onSort}
                    sortBy={sortBy}
                    align={align}
                    name={name}
                    sortColumn={sortColumn}
                    sortType={sortType}
                    clarification={clarification}
                  />
                )
              )}
            </TableRow>
          </TableHead>
        )}
        <StyledTableBody
          verticalborders={verticalBorders ? VERTICAL_BORDERS_POSITIVE : ""}
          striped={striped ? STRIPED_POSITIVE : ""}
          compact={compact ? COMPACT_POSITIVE : ""}
        >
          {children}
        </StyledTableBody>
        <StyledTableFooter>{footer}</StyledTableFooter>
      </MuiTable>
      {loading && (!React.Children.count(children) || loadingWithData) && (
        <StyledLoadingPlaceholder
          caption={loadingCaption}
          indicatorInViewport={indicatorInViewport}
        />
      )}
    </Wrapper>
  );
}

export default Table;

export { TableRow, TableCell };

interface TableBodyProps {
  striped?: string;
  verticalborders?: string;
  compact?: string;
}

const Wrapper = styled.div`
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  position: relative;
`;

const StyledTableBody = styled(TableBody)<TableBodyProps>`
  .MuiTableRow-root:nth-of-type(2n) {
    background-color: ${({ theme, striped }): string =>
      striped === STRIPED_POSITIVE ? theme.palette.data.blueGrey : "default"};
  }

  .MuiTableRow-root {
    .MuiTableCell-root {
      border: 1px solid ${({ theme }): string => theme.palette.grey[200]};
      border-width: ${({ verticalborders }): string =>
        verticalborders == VERTICAL_BORDERS_POSITIVE
          ? "0 1px 1px 1px"
          : "0 0 1px 0"};
    }

    .MuiTableCell-root:first-of-type {
      border-left: unset;
    }

    .MuiTableCell-root:last-child {
      border-right: unset;
    }

    @media (max-width: ${({ theme }): number =>
        theme.breakpoints.values.sm}px) {
      ${({ theme, compact }): SerializedStyles | undefined => {
        if (compact === COMPACT_POSITIVE) {
          return css`
            align-items: flex-start;
            border-bottom: 1px solid ${theme.palette.grey[400]};
            display: flex;
            flex-direction: column;
          `;
        }
      }}

      .MuiTableCell-root {
        ${({ compact }): SerializedStyles | undefined => {
          if (compact === COMPACT_POSITIVE) {
            return css`
              border: none !important;
              text-align: left;
              width: 100% !important;
            `;
          }
        }}
      }
    }
  }
`;

const StyledLoadingPlaceholder = styled(LoadingIndicator)`
  background-color: ${({ theme }): string =>
    hexToRgba(theme.palette.grey[400], 0.6)};
`;

const StyledTableFooter = styled(TableFooter)`
  .MuiTableCell-footer {
    font-size: 15px;
    font-weight: 900;
  }
`;
