import Typography from "@material-ui/core/Typography";
import * as React from "react";

import styled from "~/components/core/styled";

interface Props {
  title?: string;
  description?: string;
  className?: string;
}

const VoidPlaceholder: React.FC<Props> = ({
  title = "No Results Found!",
  description = "We could not find any data based on your filters. Try different filters or reset them.",
  className
}: Props): JSX.Element => {
  return (
    <StubContainer className={className}>
      <Typography variant="h4">{title}</Typography>
      <StubDescription variant="subtitle2">{description}</StubDescription>
    </StubContainer>
  );
};

export default VoidPlaceholder;

const StubContainer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-flow: column;
  flex-wrap: wrap;
  height: 100%;
  justify-content: center;
  text-align: center;
`;

const StubDescription = styled(Typography)`
  margin-top: 25px;
  text-align: center;
  width: 50%;
`;
