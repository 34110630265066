import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import * as React from "react";

import styled from "~/components/core/styled";
import LightTooltip from "~/components/core/Tooltip";

export interface Props {
  clarification?: string;
  className?: string;
}

const ClarificationIcon: React.FC<Props> = ({
  clarification,
  className
}: Props): JSX.Element | null => {
  if (!clarification) return null;

  return (
    <LightTooltip
      placement="left"
      className={className}
      title={clarification}
      arrow
    >
      <Icon />
    </LightTooltip>
  );
};

export default ClarificationIcon;

const Icon = styled(InfoOutlinedIcon)`
  color: ${({ theme }): string => theme.palette.primary.lighter};
  margin-right: 12px;
`;
