import { endOfWeek, startOfWeek } from "date-fns";
import dayjs from "dayjs";

import { CUSTOM_PERIOD, DATE_UNIT, PERIOD } from "~/constants/dates";
import { Range } from "~/declarations/filters";
import {
  PERMISSION_CODENAME,
  UserPermission
} from "~/declarations/models/User";
import { getCachedFirstSeenDate } from "~/utils/cachedFirstSeenDate";
import { getCachedWeekStart } from "~/utils/cachedWeekStart";

export enum FILTER_TYPE {
  period,
  scale,
  lesson,
  subject,
  student,
  search,
  minute
}

export const ALL_SUBJECTS = "All subjects";

export const INIT_SUBJECT_VARIANTS = [
  {
    name: ALL_SUBJECTS
  }
];

export enum SCALE {
  hourly = "hourly",
  daily = "daily",
  weekly = "weekly",
  monthly = "monthly"
}

export enum LESSON {
  moderated = "moderated",
  pending = "pending"
}

export function scaleFromString(value: string): SCALE {
  switch (value) {
    case SCALE.hourly:
      return SCALE.hourly;
    case SCALE.daily:
      return SCALE.daily;
    case SCALE.weekly:
      return SCALE.weekly;
    case SCALE.monthly:
      return SCALE.monthly;
  }
  throw new Error(`invalid scale value ${value}`);
}

export interface DropDownFilterItem {
  label: string;
  value: string;
  description: string;
}

export enum SESSION {
  moderated = "moderated",
  all = "all"
}

export function sessionFromString(value: string): SESSION {
  switch (value) {
    case SESSION.all:
      return SESSION.all;
    case SESSION.moderated:
      return SESSION.moderated;
  }
  throw new Error(`invalid session value ${value}`);
}

export const SESSION_VARIANTS: DropDownFilterItem[] = [
  {
    label: "Moderated",
    value: SESSION.moderated,
    description:
      "By default, we will show sessions that have already been moderated and reviewed by the Pear Deck Tutor staff. Each session takes about 3-4 days to be reviewed, so the data shown might be less than the actual total amount."
  },
  {
    label: "With pending",
    value: SESSION.all,
    description:
      "To get a more accurate data of the numbers and graphs, include the ones pending."
  }
];

export const SCALE_VARIANTS: DropDownFilterItem[] = [
  {
    label: "Hourly",
    value: SCALE.hourly,
    description: ""
  },
  {
    label: "Daily",
    value: SCALE.daily,
    description: ""
  },
  {
    label: "Weekly",
    value: SCALE.weekly,
    description: ""
  },
  {
    label: "Monthly",
    value: SCALE.monthly,
    description: ""
  }
];

export const PERMISSION_VARIANTS: UserPermission[] = [
  {
    name: "Lessons",
    codename: PERMISSION_CODENAME.lessons
  },
  {
    name: "Writing Lab",
    codename: PERMISSION_CODENAME.writingLabs
  },
  {
    name: "Students",
    codename: PERMISSION_CODENAME.students
  },
  {
    name: "Reports",
    codename: PERMISSION_CODENAME.reports
  },
  // TODO: Temporary hidden due to API isn't ready
  // {
  //   name: "Trending",
  //   codename: PERMISSION_CODENAME.trending
  // },
  {
    name: "User Management",
    codename: PERMISSION_CODENAME.manageUsers
  }
];

export const PER_PAGE = [25, 50, 100];

function dynamicWeekStart(day: dayjs.Dayjs): dayjs.Dayjs {
  return dayjs(
    startOfWeek(day.toDate(), { weekStartsOn: getCachedWeekStart() })
  );
}

function dynamicWeekEnd(day: dayjs.Dayjs): dayjs.Dayjs {
  return dayjs(endOfWeek(day.toDate(), { weekStartsOn: getCachedWeekStart() }));
}

function makeNDaysRange(n: number): () => Range {
  return (): Range => {
    const today = dayjs().startOf(DATE_UNIT.day);
    const fromDt = today.subtract(n, DATE_UNIT.day);
    const tillDt = today.subtract(1, DATE_UNIT.ms);
    return [fromDt, tillDt];
  };
}

export const RANGE_GETTERS = {
  [PERIOD.lastWeek]: (): Range => {
    const tillDt = dynamicWeekStart(dayjs()).subtract(1, DATE_UNIT.ms);
    const fromDt = dynamicWeekStart(tillDt);
    return [fromDt, tillDt];
  },
  [PERIOD.thisWeek]: (): Range => {
    const currentDate = dayjs();
    const tillDt = dynamicWeekEnd(currentDate);
    const fromDt = dynamicWeekStart(currentDate);
    return [fromDt, tillDt];
  },
  [PERIOD.lastMonth]: (): Range => {
    const tillDt = dayjs()
      .startOf(DATE_UNIT.month)
      .subtract(1, DATE_UNIT.ms);
    const fromDt = tillDt.startOf(DATE_UNIT.month);
    return [fromDt, tillDt];
  },
  [PERIOD.thisMonth]: (): Range => {
    const tillDt = dayjs()
      .endOf(DATE_UNIT.month)
      .subtract(1, DATE_UNIT.ms);
    const fromDt = tillDt.startOf(DATE_UNIT.month);
    return [fromDt, tillDt];
  },
  [CUSTOM_PERIOD.last30Days]: makeNDaysRange(30),
  [CUSTOM_PERIOD.last60Days]: makeNDaysRange(60),
  [CUSTOM_PERIOD.last90Days]: makeNDaysRange(90),
  [CUSTOM_PERIOD.last365Days]: makeNDaysRange(365),
  [CUSTOM_PERIOD.thisYear]: (): Range => {
    const tillDt = dayjs();
    const fromDt = dayjs().startOf(DATE_UNIT.year);
    return [fromDt, tillDt];
  },
  [CUSTOM_PERIOD.allTime]: (): Range => {
    const tillDt = dayjs().endOf(DATE_UNIT.day);
    const fromDt = getCachedFirstSeenDate() ?? dayjs(0);
    return [fromDt, tillDt];
  }
};

export enum SORT {
  desc = "desc",
  asc = "asc",
  noSort = "noSort"
}
